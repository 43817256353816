import React, { FC } from 'react';
import classNames from 'classnames';
import { Button } from '@mantine/core';
import { CloseIcon } from '@/shared/assets/svg/index';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { CloseBtn } from '@/shared/assets/icons';
import { MapPlacemarkInfoTypes } from './map-placemark-info.types';
import styles from './map-placemark-info.module.scss';
import { RichText } from '../rich-text';

export const MapPlacemarkInfo: FC<MapPlacemarkInfoTypes> = ({
  title,
  street,
  services,
  time,
  inForm,
  showInfo,
  onSelect,
}) => {
  const { isMobile } = useMediaQuery();

  return inForm ? (
    isMobile ? (
      <BottomSheet className={styles.bottomsheet__root} open={showInfo || false} onDismiss={onSelect}>
        <div className={styles.bottomsheet}>
          {title && <h2 className={styles.header__title}>{title}</h2>}
          {street && <h3 className={styles.header__street}>{street}</h3>}
          {time && <div className={styles.time}>Часы работы: {time}</div>}
          <Button onClick={onSelect} className={styles.button}>
            Выбрать это отделение
          </Button>
        </div>
      </BottomSheet>
    ) : (
      <div className={classNames(styles.container, styles.centered)}>
        <div className={styles.bottomsheet}>
          <div onClick={onSelect} className={styles.close}>
            <CloseBtn color='#414961' />
          </div>
          {title && <h2 className={styles.header__title}>{title}</h2>}
          {street && <h3 className={styles.header__street}>{street}</h3>}
          {time && <div className={styles.time}>Часы работы: {time}</div>}
          <Button onClick={onSelect} className={styles.button}>
            Выбрать это отделение
          </Button>
        </div>
      </div>
    )
  ) : (
    <div className={styles.container}>
      <button type='button' className={styles.closeBtn} onClick={onSelect}>
        <CloseIcon />
      </button>
      <div className={styles.header}>
        {title && <h2 className={styles.header__title}>{title}</h2>}
        {street && <h3 className={styles.header__street}>{street}</h3>}
      </div>
      {time && <div className={styles.time}>Часы работы: {time}</div>}
      {services && <RichText>{services}</RichText>}
      {/* <PhoneCopie number={'+7 990 555-66-77'} /> */}
    </div>
  );
};
