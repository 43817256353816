import React, { FC, useRef, useEffect, useState, ReactElement, memo } from 'react';
import { Map as YMap, ZoomControl, Placemark, useYMaps } from '@pbe/react-yandex-maps';
import styles from './map.module.scss';
import { LayoutContext, LocalSuperClass, MapProps } from './map.types';
import { Placemark as PlacemarkComponent } from './placemark';
import { renderToString } from 'react-dom/server';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import cn from 'classnames';

const Map: FC<MapProps> = memo(
  ({ onMapReady, points, handlePlacementClick, activePlacemarkId, infoObject, className }) => {
    const [isMapReady, setMapReady] = useState<boolean>(false);
    const { isDesktopMax } = useMediaQuery();
    const ymaps = useYMaps(['Map', 'Placemark', 'templateLayoutFactory']);
    const mapInstance = useRef<ymaps.Map>();
    const [longitude, setLongitude] = useState(45.3);
    const [latitude, setLatitude] = useState(34.5);
    const [zoomValue, setZoomValue] = useState(8);

    useEffect(() => {
      if (!ymaps) {
        return;
      }

      ymaps.ready(() => {
        const center = mapInstance.current?.getCenter();
        const zoom = mapInstance.current?.getZoom();
        if (center) {
          setLongitude(center[0]);
          setLatitude(center[1]);
        }
        zoom && setZoomValue(zoom);
        onMapReady && onMapReady();
        !isDesktopMax && mapInstance.current?.behaviors.disable('scrollZoom');
        mapInstance.current?.setCenter([longitude, latitude]);
        setMapReady(true);
      });
    }, [ymaps, onMapReady, mapInstance]);

    const createLayout = (Component: React.ReactElement, callback: (context: LayoutContext) => void) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:next-line
      return ymaps?.templateLayoutFactory.createClass(renderToString(Component as ReactElement), {
        build: function (this: LayoutContext) {
          (this.constructor as LocalSuperClass).superclass.build.call(this);

          return callback(this);
        },
      });
    };

    const buildPlacemark = (context: LayoutContext) => {
      const placemarkContainer = context.getParentElement().getElementsByClassName('placemark')[0] as HTMLElement;

      // Важно!: не использовать x, y, top, left, right
      const { height, width } = placemarkContainer.getBoundingClientRect();

      // Нивелирует разницу с transform: translate
      const offsetHeight = height / 2;
      const offsetWidth = width / 2;

      // Задает область клика по Placemark
      context.getData().options.set('shape', {
        type: 'Rectangle',
        coordinates: [
          [-width + offsetWidth, -height + offsetHeight],
          [width - offsetWidth, height - offsetHeight],
        ],
      });
    };

    return (
      <YMap
        instanceRef={mapInstance}
        defaultState={{
          center: [longitude, latitude],
          zoom: zoomValue,
          controls: [],
        }}
        className={cn(styles.container, className)}
      >
        {infoObject}
        <ZoomControl options={{ position: { right: 15, top: 150 } }} />
        {isMapReady &&
          points &&
          points?.map((point) => {
            return (
              <Placemark
                geometry={[point.coordinates.latitude, point.coordinates.longitude]}
                key={point.id}
                properties={point}
                options={{
                  iconLayout: createLayout(
                    <PlacemarkComponent isActive={point.id === activePlacemarkId} />,
                    buildPlacemark,
                  ),
                }}
                onClick={handlePlacementClick}
              />
            );
          })}
      </YMap>
    );
  },
);

Map.displayName = 'Map';
export { Map };
