import React from 'react';
import cn from 'classnames';
import PinIcon from '@/../public/assets/img/geoPlacemark.svg';
import PinIconActive from '@/../public/assets/img/geoPlacemarkActive.svg';

import styles from './map.module.scss';

export const Placemark = ({ isActive }: { isActive?: boolean }) => (
  <div className={cn(styles.placemark, 'placemark')}>
    {isActive ? <PinIconActive /> : <PinIcon />}
  </div>
);
